/* CSS */
import './Root.css';

/* React */
import React from 'react';

/* UI Components (for Login) */
import {
	Button,
	Form
} from '../UI';

/* Fancy graphics (for Intro) */
import Fade from 'react-reveal/Fade';

/* Google Maps (for Root) */
import {
	GoogleMap,
	LoadScript,
	InfoWindow,
	Marker
} from '@react-google-maps/api';

/*
 * localStorage proxy
 */
import localStorageProxy from 'local-storage-proxy';
const _localStorage = localStorageProxy('Root.js');

/*
 * Keys
 */
const keys = require('./keys.js');
const googleMapsAPIKey = keys.googleMapsAPIKey;

/*
 * Icons
 */
const logo = require('./images/logo.svg').default;
const iconMap = {};
for (const type of ['popsicle']) {
	iconMap[type] = require(`./images/icons/${type}.png`).default;
}

const headerIconMap = {};
for (const type of ['coin_gecko', 'twitter', 'discord']) {
	headerIconMap[type] = require(`./images/logos/${type}.svg`).default;
}

/*
 * Fonts
 */
const cityFontMap = {
	default: 'Sans',
	london: 'Golf Club Homicide',
	sydney: 'Bangers',
	losangelos: 'Faster One'
};

/*
 * Cache images
 */
const cache_images = {
	main_curtains: require('./images/curtains/main_curtains.png').default,
	top_curtain: require('./images/curtains/top_curtain.png').default
}

function do_cache_images() {
	if (window.CACHE_IMAGES === undefined) {
		window.CACHE_IMAGES = {};
	}
	if (window.CACHE_IMAGES.top_curtain === undefined) {
		window.CACHE_IMAGES.top_curtain = new Image();
		window.CACHE_IMAGES.top_curtain.src = cache_images.top_curtain;
	}
	if (window.CACHE_IMAGES.main_curtains === undefined) {
		window.CACHE_IMAGES.main_curtains = new Image();
		window.CACHE_IMAGES.main_curtains.src = cache_images.main_curtains;
	}
}

/*
 * Local Storage
 */


/*
 * Our Component
 */
function logError(error) {
	// alert(error);
	console.error(error);
}

function Header(props) {
	let city = props?.city;
	if (!city) {
		city = 'default';
	}

	return(
		<div>
			<div id="header">
				<span class="logo">
					<a href="https://popsicle.finance/"><img src={logo} alt="Popsicle Logo"/></a>
					<span id="popsicle">Popsicle</span>
					<span id="hunt" style={{fontFamily: cityFontMap[city]}}>Hunt</span>
				</span>
				<span class="links">
					<span class="link"><a id="home" href="https://popsicle.finance/">Home</a></span>
					<span class="link"><a id="coin_gecko" href="https://coingecko.com/en/coins/popsicle-finance"><img style={{height: '1em'}} src={headerIconMap['coin_gecko']} alt="Coin Gecko"/></a></span>
					<span class="link"><a id="twitter" href="https://twitter.com/popsiclefinance?s=21"><img style={{height: '1em'}} src={headerIconMap['twitter']} alt="Twitter"/></a></span>
					<span class="link"><a id="discord" href="https://discord.gg/popsicle"><img style={{height: '1em'}} src={headerIconMap['discord']} alt="Discord"/></a></span>
				</span>
			</div>
		</div>
	);
}

class Intro extends React.Component {
	static defaultProps = {
		duration: 5000
	}

	constructor(...args) {
		super(...args);

		let done = _localStorage.Intro?.done
		if (done === undefined) {
			done = false;
		}

		this.state = {
			done,
			start: false
		}

	}

	componentDidMount() {
		do_cache_images();
		setTimeout(() => {
			this.done();
		}, (this.props.duration + 2000));
	}

	done() {
		if (_localStorage.Intro === undefined) {
			_localStorage.Intro = {};
		}

		_localStorage.Intro.done = true;
		this.setState({
			done: true
		});
	}

	render() {
		if (this.state.done === true) {
			return(null);
		}

		setTimeout(() => {
			this.setState({ start: true })
		}, 0);

		const commonProps = {
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: 0,
			left: 0
		};

		return(
			<>
				<Fade opposite when={!this.state.start} duration={this.props.duration}>
					<div style={{ ...commonProps, zIndex: 100001  }}><img style={{ width: '100%', height: '100%' }} src={cache_images.top_curtain}/></div>
				</Fade>
				<div style={{ ...commonProps, zIndex: 100000 }}><img style={{ width: '100%', height: '100%' }} src={cache_images.main_curtains}/></div>
			</>
		);
	}
}

class Login extends React.Component {
	constructor(...args) {
		super(...args);

		if (_localStorage.Login?.password !== undefined) {
			this.submitPassword(_localStorage.Login.password);
		}

		this.passwordRef = React.createRef();
	}

	submitPassword(password) {
		if (this.props?.onSubmit) {
			this.props.onSubmit(password);
		}
	}

	componentDidMount() {
		do_cache_images();
	}

	static savePassword(password) {
		if (_localStorage.Login === undefined) {
			_localStorage.Login = {};
		}
		_localStorage.Login.password = password;
	}

	async handleSubmit() {
		const current_password = this.passwordRef.current.value;
		this.submitPassword(current_password);
		this.passwordRef.current.value = '';
	}

	render() {
		return(
			<>
				<Form.Group controlId="formBasicPassword">
					<Form.Label>Password</Form.Label>
					<Form.Control type="password" placeholder="Password" ref={this.passwordRef} />
				</Form.Group>
				<Button variant="primary" onClick={() => { this.handleSubmit(); }}>Submit</Button>
			</>
		);
	}
}

class Root extends React.Component {
	constructor(...args) {
		super(...args);

		this.map = undefined;
		this.markerMap = {};
		this.state = {
			mapData: {},
			password: undefined,
			currentInfoWindowId: undefined,
			currentInfoWindow: undefined,
			currentInfoWindowKey: undefined
		};

		setInterval(async () => {
			try {
				await this.getMapData();
			} catch (update_error) {
				logError(update_error);
			}
		}, 30000);

	}

	static async fetchMapData(password) {
		let newMap;
		try {
			const response = await fetch(`/password/${password}/map.json?invalidate=${Math.random()}`);
			if (!response.ok) {
				return(undefined);
			}

			newMap = await response.json();

		} catch (fetch_error) {
			logError(fetch_error);

			return(undefined);
		}

		return(newMap);
	}

	async getMapData() {
		if (this.state.password === undefined) {
			return;
		}

		const newMap = await Root.fetchMapData(this.state.password);
		if (!newMap) {
			return;
		}

		let updateMap = false;
		if (this.state.mapData?.version === undefined) {
			updateMap = true;
		} else if (this.state.mapData?.version !== newMap?.version) {
			updateMap = true;
		}

		if (!updateMap) {
			return;
		}

		Login.savePassword(this.state.password);

		const oldBounds = this.bounds;
		this.setState({
			mapData: newMap
		}, () => {
			this.conditionallyRecenterMap(oldBounds);
		});
	}

	conditionallyRecenterMap(oldBounds) {
		if (!this.map) {
			return;
		}

		if (!window.google?.maps) {
			return;
		}

		/*
		 * If there are no features, use the included center and zoom
		 */
		if (this.features.length === 0) {
			if (!this.state.mapData?.center) {
				return;
			}

			this.zoomOnFeature({
				position: {
					lat: this.state.mapData.center.lat,
					lon: this.state.mapData.center.lon,
				}
			}, this.state.mapData.zoom);

			return;
		}

		/* Fit the map to the bounds */
		const { min_lat, min_lon, max_lat, max_lon } = this.bounds;

		if (oldBounds !== undefined) {
			if (
				oldBounds.min_lat === min_lat &&
				oldBounds.max_lat === max_lat &&
				oldBounds.min_lon === min_lon &&
				oldBounds.max_lon === max_lon
			) {
				return;
			}
		}

		/*
		 * If there is exactly 1 feature, center on it and zoom appropriately
		 */
		if (this.features.length === 1) {
			this.zoomOnFeature(this.features[0], this.state.mapData?.zoom);

			return;
		}

		const sw = new window.google.maps.LatLng(min_lat, min_lon);
		const ne = new window.google.maps.LatLng(max_lat, max_lon);
		const bound = new window.google.maps.LatLngBounds(sw, ne);

		console.debug('Resetting to fit bounds:', {
			sw: {min_lat, min_lon},
			ne: {max_lat, max_lon}
		}, '; old:', {
			sw: {min_lat: oldBounds?.min_lat, min_lon: oldBounds?.min_lon},
			ne: {max_lat: oldBounds?.max_lat, max_lon: oldBounds?.max_lon}
		});

		this.map.fitBounds(bound);
	}

	zoomOnFeature(feature, zoom = 16) {
		if (!this.map) {
			return;
		}

		this.map.setCenter({
			lat: feature.position.lat,
			lng: feature.position.lon
		});
		this.map.setZoom(zoom);
	}

	get features() {
		let features = this.state.mapData?.features;
		if (features === undefined) {
			features = [];
		}

		/* Ensure each feature has a stable ID */
		features = features.map(function(feature) {
			return({
				...feature,
				id: ['feature', String(feature.position.lat), String(feature.position.lon)].join('_')
			});
		});

		return(features);
	}

	get bounds() {
		let max_lon = -360, max_lat = -360, min_lon = 360, min_lat = 360;
		for (const feature of this.features) {
			if (feature.position.lat > max_lat) {
				max_lat = feature.position.lat;
			}
			if (feature.position.lat < min_lat) {
				min_lat = feature.position.lat;
			}
			if (feature.position.lon > max_lon) {
				max_lon = feature.position.lon;
			}
			if (feature.position.lon < min_lon) {
				min_lon = feature.position.lon;
			}
		}
	
		return({ max_lat, max_lon, min_lat, min_lon });
	}

	get city() {
		let city = 'default';

		if (this.state?.mapData?.city) {
			city = this.state.mapData.city.toLowerCase();
		}

		if (!cityFontMap[city]) {
			city = 'default';
		}

		return(city);
	}

	render() {
		if (this.state.password === undefined) {
			return(<Login onSubmit={async (password) => {
				const data = await Root.fetchMapData(password);
				if (!data) {
					return(false);
				}

				this.setState({
					password
				}, () => {
					this.getMapData();
				});

				return(true);
			}}/>);
		}

		let currentInfoWindow;
		if (this.state.currentInfoWindowId) {
			const marker = this.markerMap[this.state.currentInfoWindowId];

			if (marker) {
				const feature = this.state.currentInfoWindow;
				currentInfoWindow = (
					<InfoWindow
						key={this.state.currentInfoWindowKey}
						anchor={marker}
						onCloseClick={() => {
							this.setState({
								currentInfoWindowId: undefined,
								currentInfoWindow: undefined,
								currentInfoWindowKey: undefined
							});
						}}
					>
						<div class="map_infobox">{feature.text}</div>
					</InfoWindow>
				);
			}
		}


		return(
			<>
				<Intro/>
				<Header city={this.city}/>
				<LoadScript googleMapsApiKey={googleMapsAPIKey}>
					<GoogleMap
						id='map'
						mapContainerStyle={{}}
						onLoad={(map) => {
							this.map = map;
							this.conditionallyRecenterMap();
						}}
					>
						{currentInfoWindow}
						{this.features.map((feature) => {
							const lat = feature.position?.lat;
							const lng = feature.position?.lon;
							const icon = iconMap[feature.type];
							const id = feature.id;
							return(
								<Marker
									key={`marker_${id}`}
									position={{lat, lng}}
									icon={icon}
									onLoad={(marker) => {
										this.markerMap[id] = marker;
									}}
									onUnmount={() => {
										delete this.markerMap[id];
									}}
									onClick={() => {
										this.setState({
											currentInfoWindowId: id,
											currentInfoWindow: feature,
											currentInfoWindowKey: `marker_${Math.random()}`
										});
									}}
									onDblClick={() => {
										if (this.zoomedOnFeature === id) {
											this.zoomedOnFeature = undefined;
											this.conditionallyRecenterMap();
										} else {
											this.zoomedOnFeature = id;
											this.zoomOnFeature(feature);
										}
									}}
								/>
							);
						})}
					</GoogleMap>
				</LoadScript>
			</>
		);
	}
}

export default Root;
