import 'bootstrap/dist/css/bootstrap.min.css';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export {
	Button,
	Form
};

const _to_export = {
	Button,
	Form
};

export default _to_export;
